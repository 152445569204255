import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {

  baseUrl: any = '';
  mediaURL: any = '';

  constructor(
    private http: HttpClient
    ) 
    {
    this.baseUrl = environment.baseUrl;
    this.mediaURL = environment.mediaURL;
   }


  Login(payload: any) {
    return this.http.post((this.baseUrl + environment.apiUrls.apis.Login),payload, {
      headers: new HttpHeaders().set('Basic', `${environment.authToken}`)
    });
  }

  getParameterList() {
    return this.http.get((this.baseUrl + environment.apiUrls.apis.getParameterList), {
      headers: new HttpHeaders().set('Basic', `${environment.authToken}`)
    }).toPromise();
  }
}
