import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  {
    path: 'home-page',
    loadChildren: () => import('./pages/home-page/home-page.module').then( m => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  
  {
    path: 'changepassword',
    loadChildren: () => import('./pages/changepassword/changepassword.module').then( m => m.changepasswordPageModule)
  },
  {
    path: 'viewprofile',
    loadChildren: () => import('./pages/viewprofile/viewprofile.module').then( m => m.viewprofilePageModule)
  },
  {
    path: 'menu',
    component:MenuComponent
  },
  
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },

  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },  
 
  // {
  //   path: 'tasks',
  //   loadChildren: () => import('./pages/dashboard/components/tasks/tasks.component').then( m => m.tasksComponent)
  // },
  // {
  //   path: 'payslips',
  //   loadChildren: () => import('./pages/dashboard/components/payslips/payslips.component').then( m => m.payslipsComponent)
  // },
 

  
    {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full'
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],

  exports: [RouterModule]
})
export class AppRoutingModule {}
